import React from "react";
import stars from "../assets/img//gif/stars.gif";
import bad from "../assets/img/gif/badminton.gif";
import dessin from "../assets/img/gif/dessin.gif";
import rubis from "../assets/img/gif/rubis.gif";
import uk from "../assets/img/gif/uk.gif";
import ecriture from "../assets/img/gif/ecrire.gif";
import livres from "../assets/img/gif/lire.gif";

const Infos = () => {
  return (
    <div id="infos">
      <div className="encart-pourquoi">
        <h2>Pourquoi travailler avec moi ?</h2>
        <p>
          Forte de mes différentes expériences à l’école, en stage ou en tant
          que freelance, j’ai acquis diverses capacités qui me permettent d’être
          autonome, polyvalante, créative, d'avoir une grande attention aux
          détails et de bien communiquer avec les clients pour comprendre leurs
          besoins.
        </p>
      </div>

      <h2>Formations.</h2>
      <div className="formations">
        <div className="formations-container">
          <p className="dates">
            2024
            <br />
            2025
          </p>
          <span className="boules">•</span>
          <p>
            <span className="noms-ecoles">
              Formation Concepteur Développeur d'Application
            </span>
            <br />
            3W Academy (React - Symfony) - Alternance
          </p>
        </div>

        <div className="formations-container">
          <p className="dates">
            2023
            <br />
            2024
          </p>
          <span className="boules">•</span>
          <p>
            <span className="noms-ecoles">Formation Développeur WordPress</span>
            <br />
            OpenClassroom (WordPress, Php, Javascript)
          </p>
        </div>

        <div className="formations-container">
          <p className="dates">
            2018
            <br />
            2019
          </p>
          <span className="boules">•</span>
          <p>
            <span className="noms-ecoles">
              Diplômée en Design graphique et Motion design
            </span>
            <br />
            École Pivaut (Photoshop, Illustrator, InDesign, After Effects)
          </p>
        </div>
      </div>

      <div className="stars">
        <img src={stars} alt="Etoiles" className="star" />
      </div>

      <h2>Intérêts.</h2>
      <div className="passions-container">
        <div className="passions">
          <img
            className="img-interet"
            src={dessin}
            alt="Crayon coloriant un rond"
          />
          <p>dessin.</p>
        </div>
        <div className="passions">
          <img
            className="img-interet img-livre"
            src={livres}
            alt="Pile de livres"
          />
          <p>fantasy - SF.</p>
        </div>
        <div className="passions">
          <img className="img-interet  img-rubis" src={rubis} alt="Rubis" />
          <p>zelda.</p>
        </div>
        <div className="passions">
          <img
            className="img-interet img-uk"
            src={uk}
            alt="Drapeau du Royaume-Uni"
          />
          <p>royaume-uni.</p>
        </div>
        <div className="passions">
          <img
            className="img-interet img-ecrire"
            src={ecriture}
            alt="Plume écrit sur une feuille"
          />
          <p>écriture.</p>
        </div>
        <div className="passions">
          <img
            className="img-interet img-bad"
            src={bad}
            alt="Raquette de badminton et son volant"
          />
          <p>badminton.</p>
        </div>
      </div>
    </div>
  );
};

export default Infos;
