import React from "react";

const EncartContact = () => {
  return (
    <div className="contact">
      <div className="encart-contact">
        <h3>
          Envie de travailler avec moi ? <br />
          Laissez moi un message ✨
        </h3>
        <button
          className="btn-red btn"
          onClick={() => (window.location.href = "/#contact-link")}
        >
          Contact.
        </button>
      </div>
    </div>
  );
};

export default EncartContact;
