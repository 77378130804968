import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import logo from "../assets/img/gif/logo-gif.gif";
import stars from "../assets/img/gif/stars.gif";

const Navigation = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [lastScroll, setLastScroll] = useState(0);
  const [navbarVisible, setNavbarVisible] = useState(true);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    if (!menuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  };
  const closeMenu = () => {
    setMenuOpen(false);
    document.body.style.overflow = "";
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScroll = window.scrollY;
      if (currentScroll < lastScroll) {
        setNavbarVisible(true);
      } else {
        setNavbarVisible(false);
      }
      setLastScroll(currentScroll);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScroll]);

  return (
    <div id="navigation" style={{ top: navbarVisible ? "0" : "-90px" }}>
      <div className="primary-navigation">
        {" "}
        <div className="navigation">
          <NavLink to="/#root">
            <img src={logo} alt="Logo du Studio céhel" />
          </NavLink>
          <ul className="liens-nav">
            <NavLink to="/#root">
              <li>Accueil.</li>
            </NavLink>
            <NavLink to="/#a-propos-link">
              <li>À propos.</li>
            </NavLink>
            <NavLink to="/#realisations-link">
              <li>Réalisations.</li>
            </NavLink>
            <NavLink to="/#competences">
              <li>Compétences.</li>
            </NavLink>
            <NavLink to="/#contact-link">
              <li className="contact-box">Contact.</li>
            </NavLink>
          </ul>
        </div>
        <div
          id="btn"
          className={menuOpen ? "btn-menu active" : "btn-menu"}
          onClick={toggleMenu}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>

      <div className={menuOpen ? "menu-burger open" : "menu-burger"}>
        <img src={stars} alt="Images d'étoiles" className="bloc-etoile-top" />
        <ul>
          <NavLink to="/">
            <li className="link-border">Accueil.</li>
          </NavLink>
          <NavLink to="/#a-propos-link" onClick={closeMenu}>
            <li className="link-border">À propos.</li>
          </NavLink>
          <NavLink to="/#realisations-link" onClick={closeMenu}>
            <li className="link-border">Réalisations.</li>
          </NavLink>
          <NavLink to="/#competences" onClick={closeMenu}>
            <li className="link-border">Compétences.</li>
          </NavLink>
          <NavLink to="/#contact" onClick={closeMenu}>
            <li className="contact-box" onClick={closeMenu}>
              Contact.
            </li>
          </NavLink>
        </ul>
        <img
          src={stars}
          alt="Images d'étoiles"
          className="bloc-etoile-bottom"
        />
      </div>
    </div>
  );
};

export default Navigation;
