import React from "react";
import photoCelia from "../assets/img/photo-celia.png";

const APropos = () => {
  return (
    <div id="a-propos">
      <h2>À propos.</h2>
      <div className="a-propos-container">
        <img src={photoCelia} alt="Célia" />
        <div className="texte-apropos">
          <h2>Hello, je suis Célia.</h2>
          <p>
            <span>Développeuse full-stack (React - Symfony)</span>, j'aime en
            apprendre chaque jour davantage pour nourrir ma passion de la
            communication. Que ce soit au moyen du web, de la vidéo ou du print,
            j'aime me diversifier afin de développer au maximum mes compétences.{" "}
            <br /> <br />
            Aujourd'hui basée en Loire-Atlantique,{" "}
            <span>je recherche un contrat d'apprentissage de 12 mois</span> en
            développement full stack React-Symfony à partir de novembre 2024.
            N'hésitez pas à me contacter pour en savoir plus !
          </p>
        </div>
      </div>
      <div className="anecdotes">
        <h3>Quelques anecdotes à savoir sur moi </h3>
        <p>
          <span className="boules">•</span> J’ai une passion obsessionnelle pour
          les <span>paillettes</span>, plus ça brille mieux c’est.
        </p>
        <p>
          <span className="boules">•</span> J’adore la <span>mer</span> mais je
          déteste m’y baigner, un comble pour une Bretonne.
        </p>
        <p>
          <span className="boules">•</span> J’aimerais avoir une{" "}
          <span>mini ferme</span> plus tard : âne, moutons, poules…
        </p>
        <p>
          <span className="boules">•</span> Je passe mon temps à{" "}
          <span>ranger</span>, tout ce qui me passe sous la main doit être à sa
          place.
        </p>
        <p>
          <span className="boules">•</span> Je suis une <span>couche tard</span>
          , je réfléchis et travail mieux le soir.
        </p>
        <p>
          <span className="boules">•</span> J’adore <span>lire</span> bien que
          je n’arrive pas assez souvent à trouver le temps pour.
        </p>
      </div>
      <div className="banniere-more">
        <h1 className="h3-desktop">Créative | Autonome | Polyvalente</h1>
        <h1 className="h3-mobile">
          Créative <br /> Autonome <br /> Polyvalente
        </h1>
        <div className="buttons">
          <a
            className="btn-red btn"
            href="/CV.pdf"
            download="CV_Celia_Letellier.pdf"
          >
            Télécharger mon CV
          </a>
        </div>
      </div>
    </div>
  );
};

export default APropos;
