import movieApp from "../assets/img/realisations/movie-app.png";
import movieAppVideo from "../assets/video/movie-app.mp4";

import ardm from "../assets/img/realisations/ardm.png";
import ardmVideo from "../assets/video/ardm.mp4";
import armdCharte from "../assets/img/realisations/ardm-charte.jpg";

import artbox from "../assets/img/realisations/artbox.png";
import artboxVideo from "../assets/video/artbox.mp4";
import artboxSingle from "../assets/img/realisations/artbox-single.jpg";

import planty from "../assets/img/realisations/planty.png";
import plantyVideo from "../assets/video/planty.mp4";
import plantyMaquette from "../assets/img/realisations/planty-maquette.jpg";
import plantyContact from "../assets/img/realisations/planty-contact.jpg";
import plantyRencontre from "../assets/img/realisations/planty-rencontre.jpg";

import motaPhoto from "../assets/img/realisations/mota-photo.png";
import motaVideo from "../assets/video/motaphoto.mp4";
import motaMaquette from "../assets/img/realisations/mota-maquette.jpg";
import motaSingle from "../assets/img/realisations/mota-single.jpg";
import motaLightbox from "../assets/img/realisations/mota-lightbox.jpg";

import koukaki from "../assets/img/realisations/studio-koukaki.png";
import koukakiVideo from "../assets/video/koukaki.mp4";
import koukakiMaquette from "../assets/img/realisations/koukaki-maquette.jpg";
import koukakiBurger from "../assets/img/realisations/koukaki-burger.jpg";
import koukakiExemple from "../assets/img/realisations/koukaki-exemple.jpg";

export const realisationsList = [
  {
    titreDuProjet: "Movie App",
    type: "web",
    texteRapide: "React, Scss",
    description: "Site de notation de vos films préférés",
    annee: "2024",
    theme: "React - Scss",
    categorie: "web design & site internet",
    imageSite: movieApp,
    videoSite: movieAppVideo,
    site: "http://movie-app.studio-cehel.fr/",
    explication:
      "À partir d’un exercice pour apprendre le framework React, j’ai développé l'application Movie App. Celle-ci offre une interface intuitive pour rechercher des films et constitue un espace idéal pour découvrir et enregistrer vos coups de cœur ciné. En utilisant l’API The Movie Db, j’ai intégré des fonctionnalités de recherche dynamique permettant aux utilisateurs de trouver rapidement leurs films préférés, ainsi qu'un système de favoris pour garder une liste personnalisée des films appréciés.",
    id: "5",
  },
  {
    titreDuProjet: "au rayon des Merveilles",
    type: "web",
    texteRapide: "React, Scss",
    description: "Site de notation de vos livres préférés",
    annee: "2024",
    theme: "React - Scss",
    categorie: "web design & site internet",
    imageSite: ardm,
    videoSite: ardmVideo,
    site: "https://au-rayon-des-merveilles.studio-cehel.fr/",
    explication:
      "L'application 'au rayon des Merveilles' est une création personnelle qui me sert à transformer mes livres en fiches lecture pour pouvoir les retrouver et les noter facilement. Exploitant la puissance de l'API Google Books, cette application me permet de présenter chaque ouvrage avec des détails enrichis. L'objectif était de créer une expérience utilisateur fluide et agréable, tout en mettant en valeur la diversité de mes lectures. Elle peut ainsi vous être utile pour enregistrer vos propre lectures et les retrouver facilement.",
    imagesSecondaires: [armdCharte],
    id: "6",
  },
  {
    titreDuProjet: "The Artbox",
    type: "web",
    texteRapide: "Php",
    description: "Site vitrine pour un musée",
    annee: "2023",
    theme: "Php",
    categorie: "web design & site internet",
    imageSite: artbox,
    videoSite: artboxVideo,
    code: "https://github.com/ltrcelia/The-Artbox",
    explication:
      "The Artbox, une galerie d’art moderne, avait besoin de rendre son site web plus facilement modifiable sans changer son apparence visuelle ni ajouter de nouvelles fonctionnalités. Le site existant, développé en HTML et CSS, devait être converti en PHP afin de faciliter les mises à jour de contenu. L'objectif principal était de factoriser le code en utilisant des fichiers PHP pour réduire le nombre de modifications nécessaires lors des mises à jour.",
    imagesSecondaires: [artboxSingle],
    id: "1",
  },
  {
    titreDuProjet: "Planty",
    type: "web",
    texteRapide: "WordPress, Php",
    description: "Site vitrine pour une marque de boisson",
    annee: "2023",
    theme: "Wordpress - Php",
    categorie: "web design & site internet",
    imageSite: planty,
    videoSite: plantyVideo,
    code: "https://github.com/ltrcelia/Planty",
    explication:
      "La boisson énergisante Planty avait besoin d'un site vitrine pour présenter son activité et recueillir des précommandes, en suivant les spécifications fonctionnelles fournies par le client. En collaboration avec ma cheffe de projet, et le designer UI, j'ai travaillé à partir des maquettes fournies pour créer un site qui reflète l'image de marque de Planty et crée une expérience utilisateur optimale.",
    imagesSecondaires: [plantyMaquette, plantyContact, plantyRencontre],
    id: "2",
  },
  {
    titreDuProjet: "Studio Koukaki",
    type: "web",
    texteRapide: "WordPress, Php, JavaScript, Scss",
    description: "Site vitrine pour un studio d'animation",
    annee: "2024",
    theme: "WordPress - Php - JavaScript - Scss",
    categorie: "web design & site internet",
    imageSite: koukaki,
    videoSite: koukakiVideo,
    code: "https://github.com/ltrcelia/Koukaki",
    explication:
      "Le studio d'animation Koukaki, dont le dernier film a été nominé aux Oscars du meilleur court-métrage d'animation, souhaite améliorer l'expérience utilisateur de son site en ajoutant des animations modernes et engageantes. Je devais donc dynamiser le site internet du studio d'animation Koukaki en ajoutant des animations CSS et JavaScript conformément aux spécifications fournies par le client. ",
    imagesSecondaires: [koukakiMaquette, koukakiBurger, koukakiExemple],
    id: "3",
  },
  {
    titreDuProjet: "Mota photo",
    type: "web",
    texteRapide: "WordPress, Php, JavaScript",
    description: "Site vitrine pour une photographe",
    annee: "2024",
    theme: "WordPress - Php - JavaScript",
    categorie: "web design & site internet",
    imageSite: motaPhoto,
    videoSite: motaVideo,
    code: "https://github.com/ltrcelia/motaphoto",
    explication:
      "La photographe Nathalie Mota avait besoin d'un nouveau site web sous WordPress afin qu'elle puisse gérer elle-même son hébergement et éviter de perdre à nouveau son site. Le site doit permettre de présenter ses séries de photos et de suivre les principes du Green code pour un impact environnemental réduit.",
    imagesSecondaires: [motaMaquette, motaSingle, motaLightbox],
    id: "4",
  },
];
