import React from "react";
import photoProfil from "../assets/img/photo-profil.png";

const HeroHeader = () => {
  return (
    <div id="hero-header">
      <div className="hero-header">
        <div className="text-header">
          <h1>
            Hello, je suis Célia,{" "}
            <span>développeuse Full-Stack (React - Symfony)</span>, bienvenue
            sur mon <span>portfolio de création web</span>. 🌈
          </h1>
          <div className="btn-home">
            <a href="#realisations">
              <button className="btn-red btn">
                Découvrez mes réalisations.
              </button>
            </a>
            <a href="#competences">
              <button className="btn-pink btn">
                Découvrez mes compétences.
              </button>
            </a>
          </div>
        </div>
        <img src={photoProfil} alt="Célia" className="hero-photo" />
      </div>
    </div>
  );
};

export default HeroHeader;
