import React, { useState } from "react";
import webDesign from "../assets/img/gif/web-design.gif";
import motionDesign from "../assets/img/gif/motion-design.gif";
import designGraphique from "../assets/img/gif/design-graphique.gif";
import webDesignFix from "../assets/img/WD.png";
import motionDesignFix from "../assets/img/MD.png";
import designGraphiqueFix from "../assets/img/DM.png";
import more from "../assets/img/+.png";
import { FaPhp } from "react-icons/fa";
import { FaHtml5 } from "react-icons/fa";
import { FaCss3Alt } from "react-icons/fa";
import { IoLogoJavascript } from "react-icons/io5";
import { FaReact } from "react-icons/fa";
import { FaNodeJs } from "react-icons/fa";
import { FaWordpress } from "react-icons/fa";
import { SiAdobeaftereffects } from "react-icons/si";
import { SiAdobepremierepro } from "react-icons/si";
import { SiAdobephotoshop } from "react-icons/si";
import { SiAdobeillustrator } from "react-icons/si";
import { SiAdobeindesign } from "react-icons/si";

const Competences = () => {
  const [designVisible, setDesignVisible] = useState(false);
  const [motionVisible, setMotionVisible] = useState(false);
  const [webVisible, setWebVisible] = useState(false);

  const toggleDesignVisibility = () => {
    setDesignVisible(!designVisible);
    setMotionVisible(false);
    setWebVisible(false);
    scrollToAnchor("design-graphique");
  };

  const toggleMotionVisibility = () => {
    setDesignVisible(false);
    setMotionVisible(!motionVisible);
    setWebVisible(false);
    scrollToAnchor("motion-design");
  };

  const toggleWebVisibility = () => {
    setDesignVisible(false);
    setMotionVisible(false);
    setWebVisible(!webVisible);
    scrollToAnchor("web-design");
  };

  const scrollToAnchor = (anchorId) => {
    const anchorElement = document.getElementById(anchorId);
    if (anchorElement) {
      anchorElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div id="competences">
      <h2 className="title">Compétences.</h2>
      <div className="competences">
        <div className="web-design">
          <img
            className="competences-img"
            src={webDesign}
            alt="Icone web design et site web"
          />
          <h3 id="web-design">Web design et site web.</h3>
          <div className="more" onClick={toggleWebVisibility}>
            <img src={more} alt="Icone plus" className="imgplus" />
            <p>Découvrir</p>
          </div>
        </div>

        <div className="motion-design">
          <img
            className="competences-img"
            src={motionDesign}
            alt="Icone motion design"
          />
          <h3 id="motion-design">Motion design.</h3>
          <div className="more" onClick={toggleMotionVisibility}>
            <img src={more} alt="Icone plus" className="imgplus" />
            <p>Découvrir</p>
          </div>
        </div>

        <div className="design-graphique">
          <img
            className="competences-img"
            src={designGraphique}
            alt="Icone design graphique"
          />
          <h3 id="design-graphique">Design graphique.</h3>
          <div className="more" onClick={toggleDesignVisibility}>
            <img src={more} alt="Icone plus" className="imgplus" />
            <p>Découvrir</p>
          </div>
        </div>
      </div>
      <span className="border"></span>
      {webVisible && (
        <div className="hidden-container">
          <div className="hidden">
            <img src={webDesignFix} alt="Icone web design et site web" />
            <div className="texte-container">
              <h2>Web design et site web.</h2>
              <br />
              <div className="stack">
                <div className="box">
                  <div className="flex">
                    <div className="box-logo">
                      <FaHtml5 size={40} />
                      <FaCss3Alt size={40} />
                    </div>

                    <p className="technos">HTML / CSS</p>
                    <p className="niveaux">Avancé</p>
                  </div>
                  <div className="flex">
                    <IoLogoJavascript size={40} />
                    <p className="technos">JavaScript</p>
                    <p className="niveaux">Intermédiaire</p>
                  </div>
                </div>
                <div className="box">
                  <div className="flex">
                    <FaReact size={40} />
                    <p className="technos">React</p>
                    <p className="niveaux">Intermédiaire</p>
                  </div>
                  <div className="flex">
                    <FaPhp size={50} />
                    <p className="technos">PHP</p>
                    <p className="niveaux">Intermédiaire</p>
                  </div>
                </div>
                <div className="box">
                  {" "}
                  <div className="flex">
                    <FaNodeJs size={40} />
                    <p className="technos">Node.js</p>
                    <p className="niveaux">Les bases</p>
                  </div>
                  <div className="flex">
                    <FaWordpress size={40} />
                    <p className="technos">WordPress</p>
                    <p className="niveaux">Avancé</p>
                  </div>
                </div>
              </div>

              <div className="flex">
                <p className="outils">
                  + Outils : VSC, Git, GitHub, Docker, TablePlus, Postman,
                  Figma, Adobe XD, OS Mac
                </p>
              </div>
            </div>
          </div>
          <span className="border"></span>
        </div>
      )}

      {motionVisible && (
        <div className="hidden-container">
          <div className="hidden">
            <img src={motionDesignFix} alt="Icone motion design" />
            <div className="texte-container">
              <h2>Motion design.</h2>
              <br />
              <div className="flex">
                <SiAdobeaftereffects size={40} />
                <p className="technos">Adobe After Effects</p>
                <p className="niveaux">Intermédiaire</p>
              </div>
              <div className="flex">
                <SiAdobepremierepro size={40} />
                <p className="technos">Adobe Premiere Pro</p>
                <p className="niveaux">Les bases</p>
              </div>
            </div>
          </div>
          <span className="border"></span>
        </div>
      )}

      {designVisible && (
        <div className="hidden-container">
          <div className="hidden">
            <img src={designGraphiqueFix} alt="Icone design graphique" />
            <div className="texte-container">
              <h2>Design de graphique.</h2>
              <br />
              <div className="flex">
                <SiAdobephotoshop size={40} />
                <p className="technos">Adobe Photoshop</p>
                <p className="niveaux">Intermédiaire</p>
              </div>
              <div className="flex">
                <SiAdobeillustrator size={40} />
                <p className="technos">Adobe Illustrator</p>
                <p className="niveaux">Avancé</p>
              </div>
              <div className="flex">
                <SiAdobeindesign size={40} />
                <p className="technos">Adobe InDesign</p>
                <p className="niveaux">Intermédiaire</p>
              </div>
            </div>
          </div>
          <span className="border"></span>
        </div>
      )}
    </div>
  );
};

export default Competences;
