import React from "react";
import { realisationsList } from "./RealisationsList";
import { Link } from "react-router-dom";
import { FaReact } from "react-icons/fa";
import { FaWordpress } from "react-icons/fa";
import { IoLogoJavascript } from "react-icons/io5";
import { FaPhp } from "react-icons/fa";
import { FaSass } from "react-icons/fa";

const Realisations = () => {
  const stylizeWords = (text) => {
    if (typeof text !== "string") return text;
    const words = text.split(", ");
    return words.map((word, index) => {
      let icon = null;
      switch (word.toLowerCase()) {
        case "wordpress":
          icon = <FaWordpress size={30} />;
          break;
        case "php":
          icon = <FaPhp size={40} />;
          break;
        case "javascript":
          icon = <IoLogoJavascript size={30} />;
          break;
        case "react":
          icon = <FaReact size={30} />;
          break;
        case "scss":
          icon = <FaSass size={30} />;
          break;
        default:
          icon = null;
          break;
      }
      return icon;
    });
  };

  return (
    <div id="realisations">
      <h2 className="titres">Réalisations.</h2>
      <div className="realisations">
        {realisationsList.map((projet, index) => (
          <Link
            to={`/realisation/${encodeURIComponent(
              projet.titreDuProjet.replace(/\s+/g, "-")
            )}`}
            key={index}
          >
            <div className="realisation">
              <img
                src={projet.imageSite}
                alt={`Présentation de ${projet.titreDuProjet}`}
                className="bloc"
              ></img>
              <h3>{projet.titreDuProjet}</h3>
              <p>{projet.description}</p>
              <p className="logos">
                {stylizeWords(projet.texteRapide).map((styledWord, index) => (
                  <React.Fragment key={index}>
                    {styledWord}
                    {index < stylizeWords(projet.texteRapide).length - 1 && " "}
                  </React.Fragment>
                ))}
              </p>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Realisations;
