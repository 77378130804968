import React, { useState } from "react";
import emailjs from "emailjs-com";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const emailData = {
      to_name: "Célia",
      from_name: formData.name,
      from_email: formData.email,
      message: formData.message,
    };

    emailjs
      .send(
        "service_ck28vu2",
        "template_de5jlot",
        emailData,
        "ogPvMKH0j3uCCV1x1"
      )
      .then((response) => {
        console.log(
          "Email envoyé avec succès!",
          response.status,
          response.text
        );
        alert("Message envoyé avec succès!");
        setFormData({ name: "", email: "", message: "" });
      })
      .catch((error) => {
        console.error("Erreur lors de l'envoi de l'email:", error);
        alert("Une erreur est survenue. Veuillez réessayer.");
      });
  };

  return (
    <div id="contact-link">
      <h2>Contact.</h2>
      <form onSubmit={handleSubmit}>
        <div className="bloc-contact">
          {" "}
          <div className="form">
            <div className="infos">
              <div className="flex">
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Votre nom"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="flex">
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Votre email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="message">
              <div className="flex">
                <textarea
                  id="message"
                  name="message"
                  placeholder="Votre message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
          </div>
          <button type="submit" className="btn-red btn-submit">
            Envoyer
          </button>
        </div>
      </form>
    </div>
  );
};

export default Contact;
